<template>
    <div class="list-header">
        <div class="left flex-1">
            <span class="m-right-sm" style="padding-right:40px;">下单时间： {{item.create_at}}</span>
            <span class="m-right-sm">
                <span>订单编号：</span>&nbsp;
                <span class="cursor-pointer" @click="onCopyOrder(item.order_no)">
                    <span class="m-right-xs">{{item.order_no}}</span>
                    <icon-font type="iconcopy"></icon-font>
                </span>
            </span>
        </div>

        <div class="right">
                <!-- <a-button class="m-right-sm" type="primary" @click="goApplyReceipt(item)">查看详情</a-button> -->
                <div style="color:#0079F9;cursor: pointer;font-weight: 400;" @click="onInfo(item.order_id)">查看详情</div>
            <!-- <template v-if="item.status === $val.UndoPayOrderType.wait">
                <a-button class="p-empty m-right-sm" type="link" @click="onCancelOrder(item)">取消支付</a-button>
                <a-button type="primary" @click="$router.push(`/paymusic/${item.order_id}`)">去支付</a-button>
            </template>
            <template v-else-if="item.status !== $val.UndoPayOrderType.paying">
                <template v-if="item.status === $val.UndoPayOrderType.payed">
                    <template v-if="item.is_invoiced == $val.CommonCheck.correct">
                        <a-button class="p-empty m-right-sm" type="link" @click="$emit('lookReceipt', item)">查看发票
                        </a-button>
                    </template>
                    <template v-else>
                        <a-button class="m-right-sm" type="primary" @click="goApplyReceipt(item)">申请发票</a-button>
                    </template>
                </template>
            </template> -->
        </div>
    </div>
</template>

<script>
    import OrderHeaderMixins from '@/pages/member/order/components/_mixin';

    export default {
        data() {
            return {
            }
        },
        props: {
            item: Object
        },

        mixins: [OrderHeaderMixins],
        computed: {
            orderType() {
                return this.getOrderType(this.item);
            }
        },

        components: {

        },
        methods: {
            onInfo(_id){
                if(this.item.vip_info != null)
                {
                    this.$router.push({path:'/order_v2/'+_id+"/2"});
                }else{
                    this.$router.push({path:'/order_v2/'+_id+"/1"});
                }
                
            }
        }
    }
</script>

<style lang="scss" scoped>
    .list-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* background-color: white; */
        .left {
            display: flex;
            align-items: center;
            flex-shrink: 1;

            .icon {
                font-size: 20px;
            }
        }

        .right {
            flex-shrink: 0;
        }

        .price {
            color: $black-1;
        }
    }
</style>